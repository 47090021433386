<template>
  <div class="articles">
    <div class="articles__list">
      <carousel :items-to-show="2" :breakpoints="breakpoints">
        <slide v-for="(item, index) in props.articles" :key="`blog-slide-${index}`">
          <div class="articles__slide">
            <NewsCard :data="item.attributes" />
          </div>
        </slide>
        <template #addons>
          <div class="carousel-nav">
            <Navigation />
          </div>
        </template>
      </carousel>
    </div>
  </div>
</template>

<script setup lang="ts">
import NewsCard from '~/components/blocks/news-card.vue';

interface IData {
  data: {
    seoUrl: string;
    thumbnail: Object;
    title: string;
    description: string;
  };
}

interface IProps {
  articles: { attributes: IData }[];
}

const props = defineProps<IProps>();

const breakpoints = {
  300: {
    itemsToShow: 1,
    snapAlign: 'start',
  },
  500: {
    itemsToShow: 1,
    snapAlign: 'start',
  },

  768: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
  1024: {
    itemsToShow: 2,
    snapAlign: 'start',
  },
};
</script>

<style scoped lang="scss">
.articles {
  &__slide {
    padding: 0 37px;
    height: 100%;
  }

  &__list {
    margin: 0 -37px;
  }

  .carousel-nav {
    right: 37px;
  }
}
</style>
