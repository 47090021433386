<template>
  <div class="articles">
    <ul class="articles__list">
      <li v-for="(item, index) in props.articles" :key="`blog-${index}`" class="articles__item">
        <NewsCard :data="item.attributes" />
      </li>
    </ul>
    <div class="pagination">
      <button v-if="props.count !== props.total" class="border-btn pagination__btn" @click="() => emits('show-more')">
        <span class="text">Показать еще</span>
      </button>
      <span class="colored">{{ `${props.count} из ${props.total}` }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import NewsCard from '~/components/blocks/news-card.vue';
interface IEmits {
  (event: 'show-more'): void;
}

const emits = defineEmits<IEmits>();

interface IData {
  data: {
    seoUrl: string;
    thumbnail: Object;
    title: string;
    description: string;
  };
}

interface IProps {
  articles: { attributes: IData }[];
  total: number;
  count: number;
}

const props = defineProps<IProps>();
</script>

<style scoped lang="scss">
.articles {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 74px;
    row-gap: 110px;
  }

  .pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-top: 80px;

    &__btn {
      width: 300px;
      max-width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .articles {
    &__list {
      column-gap: 50px;
      row-gap: 80px;
    }
  }
}

@media (max-width: 767px) {
  .articles {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
</style>
