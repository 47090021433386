<template>
  <div v-if="!errorMessage">
    <NewsCarousel v-if="!isNewsPage" :articles="state.articles" />
    <NewsList
      v-else-if="state.articles.length"
      :articles="state.articles"
      :total="state.total"
      :count="state.displayedCount"
      @showMore="incPagination"
    />
  </div>
  <div class="text blog__text" v-else>{{ errorMessage }}</div>
</template>

<script setup lang="ts">
import NewsCarousel from '~/components/sections/news/news-carousel.vue';
import NewsList from '~/components/sections/news/news-list.vue';
const PAGINATION_LIMIT = 6;

const { find } = useStrapi();
const route = useRoute();
const isNewsPage = route.path === '/news';
const errorMessage = ref<string>('');

const state = reactive({
  articles: [],
  total: 0,
  paginationStart: 0,
  displayedCount: 0,
});

const incPagination = () => {
  state.paginationStart += PAGINATION_LIMIT;
  getArticles();
};

const getArticles = async () => {
  try {
    const response = await find('tnews', {
      publicationState: 'live',
      populate: '*',
      sort: 'releaseDate:asc',
      pagination: isNewsPage ? { start: state.paginationStart, limit: PAGINATION_LIMIT } : { start: 0, limit: 4 },
    });

    state.articles = [...state.articles, ...response.data];
    state.displayedCount = state.articles.length;
    state.total = response.meta.pagination.total;
  } catch (error) {
    errorMessage.value = 'Не удалось загрузить ленту новостей';
  }
};

onMounted(() => {
  getArticles();
});
</script>

<style scoped lang="scss">
@import url('~/assets/styles/components/slide.scss');

.blog {
  &__text {
    padding: 36px 0;
  }
}
</style>
