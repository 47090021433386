<template>
  <article class="slide">
    <nuxt-link class="slide__link" :to="'/news/' + data.seoUrl" title="Перейти к статье"></nuxt-link>
    <div v-if="data.thumbnail.data" class="slide__img-wrapper">
      <nuxt-img :src="mediaPath + data.thumbnail.data.attributes.url" class="slide__img blog__img" />
    </div>
    <div class="slide__content">
      <h3 class="slide__title">{{ data.title }}</h3>
      <p class="slide__text">{{ data.description }}</p>
      <LinkBtn :to="'/news/' + data.seoUrl" class="slide__btn" title="Перейти к статье">Подробнее</LinkBtn>
    </div>
  </article>
</template>

<script setup lang="ts">
import LinkBtn from '~/components/buttons/link-btn.vue';
const mediaPath = useStrapiMedia();

interface IData {
  data: {
    seoUrl: string;
    thumbnail: Object;
    title: string;
    description: string;
  };
}

const props = defineProps<IData>();
</script>

<style lang="scss">
@import url('~/assets/styles/components/slide.scss');
</style>
